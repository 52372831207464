.nav-tabs .nav-item {
  margin: 0px 50px;
}

.nav-tabs .nav-link {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0px !important;
}

.nav-tabs .nav-link.active {
  border-bottom: 4px solid #0b8fb9 !important;
}

section.brand-section-container {
  background: #f7f7f7;
  margin: 40px 0;
  padding: 20px;
  border: 1px solid rgba(0,0,0,0.2);
  border-left: 0;
  border-right: 0;
}

section.brand-section-container:first-child {
  margin-top: 0;
}