.bespoke-banner {
    width: 100%;
    min-height: 270px;
    height: auto;
    object-fit: contain;

}

.bespoke-sub-banner {
    width: 50%;
    min-height: 270px;
    height: auto;
    object-fit: cover;

}

.banner {
    position: relative;
}

.sub-banner {
    position: relative;
    height: 600px;
    overflow: hidden;
    display: flex;
}

.banner-content {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    flex-direction: column;
}

.banner-sub-content {
    width: 50%;
    height: 100%;
}


.sub-content {
    background-color: white;
    width: 100%;
    padding: 20px;
    overflow: scroll;
    height: 100%;
}


.banner-text {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.banner-text-input,
.banner-description-input {
    margin-bottom: 15px;
    padding: 10px 10px;
    width: 80%;
    background: white;
    border: 2px solid black;
}


.banner-description-input {
    height: 300px;
    // padding: unset !important;
}

.banner-text-input::placeholder {
    color: white;
}


.upload-btn {
    margin-top: 10px;
    padding: 8px 25px;
    border: none;
    background-color: orange;
    font-weight: 600;
    border-radius: 5px;
}

.delete-btn {
    margin-top: 10px;
    padding: 8px 25px;
    border: none;
    background-color: red;
    font-weight: 600;
    border-radius: 5px;
}


.add-card-container {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
}


.add-card-title {
    padding: 8px 15px;
    font-size: 20px;
}

.add-card-des {
    padding: 10px;
    height: 200px;
}


.add-btn-container {
    display: flex;
    justify-content: flex-end;
}

.card-main-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.card-container {
    padding: 15px 15px;
    width: 400px;
    background-color: white;
}

.banner-content-des {
    padding-top: 5px;
    height: 380px;
    overflow: auto;
    padding-right: 10px;
}

.card-description {
    height: 200px;
    overflow: auto;
    padding-right: 10px;
}


.element-container {
    // min-height: 360px;

    width: 360px;
    background-color: #fff;
    padding: 20px;
    margin: 20px 20px;
}


.image-container {
    width: 100%;
}


.element-image {
    width: 100%;
    height: 250px;
    width: 100%;
    object-fit: contain;
}



.modalContainer {
    position: relative;
    width: 500px;
    height: 800px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}


// .upperImage{

// }

.quill {
    background-color: white !important;
    overflow: hidden;
    width: 90%;
    // margin-bottom: 60px;
}

.ql-container {
    height: unset !important;
}

.ql-editor {
    height: 340px !important;
}


.banner-btn-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.cancel-btn {
    margin-top: 10px;
    padding: 8px 25px;
    border: none;
    background-color: red;
    font-weight: 600;
    border-radius: 5px;
    margin-left: 10px;
}


@media screen and (max-width:900px) {

    .sub-banner {
        display: block;
        height: auto;
    }

    .bespoke-sub-banner {
        width: 100%;
    }

    .banner-content-title {
        font-size: 18px;
    }

    .sub-content {
        overflow: hidden;
    }

    .banner-sub-content {
        width: 100%;
        height: 400px;
    }

    .banner-content-des {
        height: 250px;
    }

    .ql-editor {
        height: 180px !important;
    }

    .banner-text-input {
        margin-bottom: 5px;
        margin-top: 10px;
    }

}



@media screen and (max-width:570px) {


    .ql-editor {
        height: 155px !important;
    }
}

@media screen and (max-width:450px) {


    .banner-content-des {
        height: 200px;
    }
}