.button {
  margin: 2px;
  padding: 2px;
  border: none;
  margin: 0;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 11px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    color: #fff;
    border: 2px solid #556ee6;
    justify-content: center;
    align-items: center;
  }
  .active {
    background-color: #13ad7f;
    border: 2px solid #05ab79;
    color: #fff;
  }
  .deActive {
    background-color: #ef3c54;
    border: 2px solid #e32c45;
    color: #fff;
  }
}

.sync {
  font-size: 12px;
  color: #111;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    color: #d5d5d5;
    pointer-events: none;
  }
}
