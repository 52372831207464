.customTooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
  font-size: 14px;
  width: 100%;
  height: 100%;
  &:hover .tooltiptext {
    visibility: visible;
  }
  .tooltiptext1 {
    min-width: 200px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 100000000000000000000;
    bottom: 170%;
    left: -160%;
    margin-left: -60px;
    font-size: 14px;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
    .pininput {
      padding: 2px;
    }
    .products {
      max-height: 100px;
      overflow-y: scroll;
    }
  }
  .tooltiptext {
    min-width: 200px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 170%;
    left: -160%;
    margin-left: -60px;
    font-size: 14px;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
  .pinProduct {
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
      transition: background-color 0.9s;
    }
  }
}

.pointerDiv {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 50%;
  transition: 0.5s ease-in;
}
