/* ==============
  Druafula
===================*/

.task-box {
  border: 1px solid $gray-300;
}

.gu-transit {
  border: 1px dashed $gray-600 !important;
  background-color: $gray-200 !important;
}
