.modalboxdivimg {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  border-radius: 8px;
  cursor: crosshair;
}

.point {
  color: orangered;
  font-size: 25px;
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.8s ease-in;
}
.pininput {
  background: white !important;
  opacity: 0.6;
  color: black;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: transparent !important;
  border-radius: 50%;
}
::-webkit-scrollbar-thumb:vertical {
  background: #7c7c7cfb !important;
  border-radius: 20px;
  height: 5px !important;
  width: 5px !important;
  // transition: all 650ms ease-in !important;
}

::-webkit-scrollbar-track:vertical:hover {
  background: #dfdfdfde !important;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #7c7c7cfb !important;
  border-radius: 20px;
  // transition: all 650ms ease-in !important;
}
::-webkit-scrollbar-track:horizontal:hover {
  background: #dfdfdfde !important;
  border-radius: 20px;
}
