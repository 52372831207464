@import "~video-react/styles/scss/video-react";
.main-section {
  display: flex;
  flex-wrap: wrap;
}

.element-container {
  // min-height: 360px;

  width: 360px;
  background-color: #fff;
  padding: 20px;
  margin: 20px 20px;
}

.section-header {
  margin-bottom: 10px;
}

.image-container {
  width: 100%;
}

.element-image {
  width: 100%;
  height: 250px;
  width: 100%;
  object-fit: contain;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-button {
  border: none;
  padding: 8px 20px;
  background-color: orange;
}

.sub-button-container {
  margin-top: 20px;
}

.upload-button-second {
  border: none;
  padding: 8px 20px;
  background-color: darkblue;
  color: white;
}

.video-container {
  padding: 20px;
}

.main-video-container {
  margin-top: 20px;
}

.video-btn-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.upload-button-second-vdo {
  border: none;
  padding: 11px 20px;
  background-color: darkblue;
  color: white;
}

.upload-btn-container {
  margin-left: 10px;
}

.button-container-product {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.8);
  position: absolute;
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 1003;
  top: 0;
  bottom: 0;
  scroll-behavior: none;
}

.modalContainer {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 10px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  font-size: 18px;
}

.product-child {
  flex: 1 0 40%; /* explanation below */
  padding: 5px 10px;
  margin: 10px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
}

.product-child :hover {
  cursor: pointer;
}

.product-sub-child {
  display: flex;
  align-items: center;
}

.icon-container {
  padding: 0px 10px;
  background: #f8f8f8;
  height: 41px;
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  height: 41px;
  // background: #F8F8F8;
  width: 97%;
}

.product-name {
  padding-left: 10px;
  overflow-wrap: break-word;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.modal-container {
  height: 600px;
  width: 600px;
}

.close-icon {
  background: none;
  border: none;
}

.product-info-container {
  margin-top: 10px;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.heroButton {
  font-family: "Montserrat";
  background-color: #ffffff;
  font-weight: 400;
  font-style: normal;
  width: 357px;
  height: 50px;
  margin-top: 10px;
}

.heroButton:hover {
  background-color: #f0f0f0;
}


.modal-container-top{
  position: relative;
}


