.paid {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
  padding: 2px 10px;
  border-radius: 10px;
}
.select {
  border: 1px solid #ededed;
  background: transparent;
  padding: 2.5px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  option {
    background-color: #fff;
    color: #333;
    border: #fff;
  }
}

.pending {
  background: #e0e9a2;
  color: #85810e;
}
.processing {
  background: #eaceff;
  color: #850e7c;
}
.shipped {
  background: #ffedd3;
  color: #8f601a;
}
.delivered {
  background: #daf4eb;
  color: #24bf89;
}
.canceled {
  background: #f7c6c6;
  color: #bf2424;
}

.weights {
  position: relative;
  input {
    width: 100%;
    border: 1px solid #ced4da;
    padding: 8px 10px;
    border-radius: 0.25rem;
  }
}
.weight_units {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.bg_head {
  background: #f3f3f3;
  border: 1px solid #e3e3e3;
}
.bg_sub_head {
  background: #e5e5e5;
  border: 1px solid #e3e3e3;
}

.tableCheckBox {
  width: 0.7rem !important;
  height: 1rem !important;
  min-height: 1rem !important;
}
.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    li.prevNext {
      background-color: transparent;
      i {
        font-size: 18px;
      }
    }
    li {
      padding: 4px 14px;
      background-color: #eff2f7;
      border-radius: 50%;
      margin: 0 3px;
      display: flex;
      align-items: center;
      cursor: pointer;
      button {
        padding: none;
        border: none;
        background-color: transparent;
      }
    }
  }
  .activePage {
    background-color: #556ee6;
    color: #fff;
  }
}

//country modal

.modalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
  top: 0;
  left: 0;
  scroll-behavior: none;
}

.modalContainer {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.country-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 10px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
}

.country-child {
  flex: 1 0 40%; /* explanation below */
  padding: 5px 10px;
  margin: 10px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
}

.country-child :hover {
  cursor: pointer;
}

.country-sub-child {
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  height: 41px;
}

.country-name {
  padding-left: 10px;
  overflow-wrap: break-word;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 17, 17, 0.9);
}


